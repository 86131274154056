import React from 'react'
import { Icon } from '@chakra-ui/react'

function MoreIcon() {
  return (
    <Icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>Ellipsis Vertical</title>
            <circle cx="256" cy="256" r="48"/>
            <circle cx="256" cy="416" r="48"/>
            <circle cx="256" cy="96" r="48"/>
        </svg>
    </Icon>
  )
}

export default MoreIcon